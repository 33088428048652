import type React from "react";
import { forwardRef } from "react";

import { clsxm } from "../../../utils/tailwind";

type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "prefix" | "suffix"
> & {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
};

const sharedAddonStyles =
  "tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-pointer-events-none tw-h-full tw-w-12";

/**
 * Overrides for the number input type, so we don't actually use type="number".
 *
 * @see https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/
 */
const numberPropsOverrides: React.HTMLProps<HTMLInputElement> = {
  onWheel: (e) => e.currentTarget.blur(),
};

const Input = forwardRef(
  (
    {
      value,
      onChange,
      type = "text",
      prefix = null,
      suffix = null,
      className,
      disabled = false,
      id,
      ...rest
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasPrefix = prefix !== null;
    const hasSuffix = suffix !== null;

    return (
      <div
        className={clsxm(
          "focus-within:tw-custom-focus-visible tw-relative tw-flex tw-h-10 tw-flex-shrink-0 tw-overflow-hidden tw-rounded tw-border tw-border-solid sm:tw-h-12",
          className,
          { "tw-bg-white": !disabled, "tw-bg-gray-100": disabled }
        )}
      >
        {hasPrefix && (
          <div
            className={clsxm(sharedAddonStyles, "tw-left-0 tw-text-secondary")}
          >
            {prefix}
          </div>
        )}
        <input
          ref={ref}
          value={value}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          type={type}
          id={id}
          disabled={disabled}
          className={clsxm(
            "tw-flex tw-flex-1 tw-bg-white tw-placeholder-secondary tw-outline-none",
            {
              "tw-pl-12": hasPrefix,
              "tw-pl-4": !hasPrefix,
              "tw-pr-12": hasSuffix,
              "tw-pr-4": !hasSuffix,
            }
          )}
          {...rest}
          {...(type === "number" ? numberPropsOverrides : {})}
        />
        {hasSuffix && (
          <div
            className={clsxm(sharedAddonStyles, "tw-right-0 tw-text-secondary")}
          >
            {suffix}
          </div>
        )}
      </div>
    );
  }
);

export { Input };
export type { InputProps };
