import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { useShareholdersQuery } from "../../api/blockchain/company";
import { votingRegisterDocumentDownload } from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { Button } from "../../components/design-system/Button";
import { Checkbox } from "../../components/design-system/Checkbox";
import { Description } from "../../components/design-system/Description";
import { EntityItem } from "../../components/design-system/EntityItem";
import { SearchIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { TableV2 } from "../../components/design-system/TableV2";
import { Toggle } from "../../components/design-system/Toggle";
import { PageWrapper } from "../../components/PageWrapper";
import { useCurrentCompany } from "../../context/account";
import { Entity } from "../../types/models/entities";
import { Shareholder } from "../../types/models/shares";
import { dateToIsoString } from "../../utils/date";
import { downloadBlob } from "../../utils/download";
import { formatNumber, formatPercentage } from "../../utils/format";
import * as monitoring from "../../utils/monitoring";

const VotingRegister = () => {
  const currentCompany = useCurrentCompany();
  const [sortBy, setSortBy] = useState("shareholder-asc");
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const [search, setSearch] = useState("");
  const [selectedShareholders, setSelectedShareholders] = useState<string[]>(
    []
  );
  const [selectedRepresentatives, setSelectedRepresentatives] = useState<
    Record<string, string>
  >({});
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const i18n = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const shareHoldersQuery = useShareholdersQuery(currentCompany?.orgNumber, "");
  const shareholders = shareHoldersQuery.data?.shareholders || [];
  const totalVotes = shareHoldersQuery.data?.totalVotes || 0;
  const entitiesQuery = useEntitiesQuery(currentCompany?.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap: Record<string, Entity> = Object.fromEntries(
    entitiesData.map((e) => [e.id, e])
  );
  useEffect(() => {
    if (selectedShareholders.length === 0) {
      setShowSelectedOnly(false);
    }
  }, [selectedShareholders]);

  if (!currentCompany) {
    return <Loading />;
  }

  const sortHolders = (holders: Shareholder[]) => {
    const [sort, sortOrder] = sortBy.split("-");
    const isAscending = sortOrder === "asc";

    if (sort === "votes") {
      return holders.sort((a, b) =>
        isAscending ? a.totalVotes - b.totalVotes : b.totalVotes - a.totalVotes
      );
    }

    return holders.sort((a, b) =>
      isAscending
        ? (entitiesMap[a.holder.id]?.name || "").localeCompare(
            entitiesMap[b.holder.id]?.name || ""
          )
        : (entitiesMap[b.holder.id]?.name || "").localeCompare(
            entitiesMap[a.holder.id]?.name || ""
          )
    );
  };

  const downloadVoting = async () => {
    setLoading(true);
    const data = selectedShareholders.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: selectedRepresentatives[curr] || "",
      }),
      {}
    );
    const response = await votingRegisterDocumentDownload(
      currentCompany.orgNumber,
      data
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `voting_${currentCompany.name}_${
          currentCompany.orgNumber
        }_${dateToIsoString(new Date())}.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading voting document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: currentCompany.orgNumber,
          },
        }
      );
    }
    setLoading(false);
  };

  if (shareHoldersQuery.isLoading || entitiesQuery.isLoading) {
    return <Loading />;
  }

  const shareholdersToShow = showSelectedOnly
    ? shareholders.filter((s) => selectedShareholders.includes(s.holder.id))
    : shareholders;
  const filteredShareholders = search
    ? shareholdersToShow.filter((s) => {
        const entity = entitiesMap[s.holder.id];
        return (
          entity?.name.toLowerCase().includes(search.toLowerCase()) ||
          entity?.refId.toLowerCase().includes(search.toLowerCase())
        );
      })
    : shareholdersToShow;
  const sortedShareholders = sortHolders(filteredShareholders);
  const rows = sortedShareholders
    .map((s) => {
      const entity = entitiesMap[s.holder.id];
      if (!entity) {
        return null;
      }
      return {
        key: s.holder.id,
        select: (
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              setSelectedShareholders((prev) =>
                prev.includes(s.holder.id)
                  ? prev.filter((i) => i !== s.holder.id)
                  : [...prev, s.holder.id]
              );
            }}
            checked={selectedShareholders.includes(s.holder.id)}
          />
        ),
        shareholder: <EntityItem value={entity} />,
        representative: (
          <Input
            value={selectedRepresentatives[s.holder.id]}
            onChange={(e) =>
              setSelectedRepresentatives((prev) => ({
                ...prev,
                [s.holder.id]: e.target.value,
              }))
            }
            placeholder={i18n.t("votingRegister.proxy.placeholder")}
          />
        ),
        votes: (
          <Description
            title={formatPercentage(s.totalVotes / totalVotes)}
            description={formatNumber(s.totalVotes)}
          />
        ),
      };
    })
    .filter((i) => i !== null);
  const selectedVotes = selectedShareholders.reduce(
    (prev, curr) =>
      prev + (shareholders.find((s) => s.holder.id === curr)?.totalVotes || 0),
    0
  );

  return (
    <PageWrapper>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-2 tw-pb-8 md:tw-flex-row">
        <div>
          <h1 className="tw-text-2xl tw-font-medium">
            {i18n.t("label.votingRegister")}
          </h1>
          <p className="tw-text-sm tw-text-secondary">
            {i18n.t("votingRegister.description")}
          </p>
        </div>
        <Button
          className="max-md:tw-w-full"
          color="primary"
          variant="solid"
          disabled={selectedShareholders.length === 0}
          onClick={downloadVoting}
          isLoading={loading}
        >
          {i18n.t("votingRegister.button")}
        </Button>
      </div>
      <hr className="tw-absolute tw-left-0 tw-h-[1px] tw-w-screen tw-bg-gray-200" />
      <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-4">
        <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4 md:tw-flex-row">
          <Input
            className="tw-h-12 tw-w-52 tw-rounded-md max-md:tw-w-full"
            placeholder={i18n.t("label.search")}
            prefix={<SearchIcon />}
            type="search"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          {selectedShareholders.length > 0 && (
            <Toggle
              label={i18n.t("votingRegister.toggle")}
              isActive={showSelectedOnly}
              onClick={() => setShowSelectedOnly(!showSelectedOnly)}
              className="tw-w-fit"
            />
          )}
        </div>
        <TableV2
          columns={[
            {
              name: "select",
              title:
                selectedShareholders.length < shareholders.length
                  ? i18n.t("votingRegister.column.selectAll")
                  : i18n.t("votingRegister.column.deselectAll"),
              key: true,
              sortable: false,
              onClick: () => {
                if (selectedShareholders.length < shareholders.length) {
                  setSelectedShareholders(shareholders.map((s) => s.holder.id));
                } else {
                  setSelectedShareholders([]);
                }
              },
            },
            {
              name: "shareholder",
              title: i18n.t("votingRegister.column.shareholder"),
              key: true,
            },
            {
              name: "representative",
              title: i18n.t("votingRegister.column.proxy"),
              key: !isTabletOrMobileDevice,
              sortable: false,
            },
            {
              name: "votes",
              title: i18n.t("votingRegister.column.votes"),
              key: !isTabletOrMobileDevice,
            },
          ]}
          data={
            isTabletOrMobileDevice
              ? rows
              : [
                  ...rows,
                  {
                    key: "total",
                    shareholder:
                      selectedShareholders.length > 0 ? (
                        <p className="tw-text-sm">
                          {i18n.t("votingRegister.total.shareholders", {
                            total: selectedShareholders.length,
                          })}
                        </p>
                      ) : undefined,
                    votes: (
                      <Description
                        title={formatPercentage(selectedVotes / totalVotes)}
                        description={i18n.t("votingRegister.total.votes")}
                      />
                    ),
                  },
                ]
          }
          sortBy={sortBy}
          setSortBy={setSortBy}
          expandedRows={expanded}
          setExpandedRows={setExpanded}
        />
      </div>
    </PageWrapper>
  );
};

export default VotingRegister;
