import type {
  POSTCompanyUnmaskValuesOrgNumberResponse,
  RawTxResponse,
} from "@capchapdev/admin-api";
import type {
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import crypto from "crypto";
import eccrypto from "eccrypto";
import { TxRejectedError } from "postchain-client";
import { z } from "zod";

import * as config from "../../config";
import { useBlockchainClient } from "../../context/blockchain";
import {
  LedgerApprovalInitializedEvent,
  LedgerRollbackPending,
} from "../../types/models/events";
import type {
  LedgerVersion,
  ShareBlockHistory,
} from "../../types/models/shares";
import * as monitoring from "../../utils/monitoring";
import type { IRequestError } from "..";
import { getAuthHeader } from "../auth";
import { BlockchainClient } from "../blockchain/client";
import useClient, { URL } from "./client";
import { RawTxResponseSchema } from "./users";

const groupSeparator = "=>";

const cache: Record<string, string> = {};

const PartsSchema = z.tuple([z.string().nonempty(), z.string().nonempty()]);
type Parts = z.infer<typeof PartsSchema>;

async function parseMaskedValue({
  masked,
  unmasked,
  isGlobal,
  orgNumber,
}: {
  masked: string;
  unmasked: string;
  isGlobal?: boolean;
  orgNumber?: string;
}): Promise<Parts> {
  const noWrapper = masked.replace("m%{", "").replace("}%", "");
  const parts = noWrapper.split(":");

  const result = PartsSchema.safeParse(parts);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { data: { parts, masked } },
    });

    return [masked, "<INVALID_DATA>"];
  }

  const [publicKeyHex, signatureBase64] = result.data;
  const pubKey = Buffer.from(publicKeyHex, "hex");
  const msg = crypto
    .createHash("sha256")
    .update(`${isGlobal ? "GLOBAL" : orgNumber}${groupSeparator}${unmasked}`)
    .digest();
  const signature = Buffer.from(signatureBase64, "base64");
  let newValue;
  try {
    await eccrypto.verify(pubKey, msg, signature);
    newValue = unmasked;
    cache[masked] = newValue;
  } catch {
    newValue = "<INVALID_DATA>";
  }

  return [masked, newValue];
}

const unmaskValues = async (
  orgNumber: string,
  values: string[],
  isGlobal?: boolean
): Promise<Record<string, string>> => {
  const { cached, uncached } = values.reduce<{
    cached: Record<string, string>;
    uncached: string[];
  }>(
    (prev, curr) => {
      const cachedValue = cache[curr];
      if (cachedValue) {
        return { ...prev, cached: { ...prev.cached, [curr]: cachedValue } };
      }

      return { ...prev, uncached: [...prev.uncached, curr] };
    },
    { cached: {}, uncached: [] }
  );
  if (uncached.length === 0) {
    return cached;
  }
  const client = useClient({ hasAuth: true });
  const response = await client<POSTCompanyUnmaskValuesOrgNumberResponse>(
    `${config.adminUrl}/Company/UnmaskValues/${orgNumber}`,
    { body: uncached }
  );
  const verifiedValues = await Promise.all(
    Object.entries(response).map(async ([masked, unmasked]): Promise<Parts> => {
      const isMasked = masked.startsWith("m%");

      if (isMasked) {
        return parseMaskedValue({ masked, unmasked, isGlobal, orgNumber });
      }

      cache[masked] = unmasked;
      return [masked, unmasked];
    })
  );

  return { ...cached, ...Object.fromEntries(verifiedValues) };
};

const createPendingLedgerApproval = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date: string,
  approve: boolean
) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/InitializeApproveLedger/${orgNumber}/${date}?approve=${approve}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const approveLedger = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date: string
) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/ApproveLedger/${orgNumber}/${date}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const rejectLedgerApproval = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  initialisedDate: string,
  date: string
) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/ApproveLedger/Rejected/${orgNumber}/${initialisedDate}/${date}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const createPendingLedgerRollback = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  currentDate: string,
  date?: string
) => {
  const client = useClient({ hasAuth: true });
  const url = date
    ? `${URL.ADMIN}/Transaction/RollbackLedger/${orgNumber}/${currentDate}/${date}`
    : `${URL.ADMIN}/Transaction/ResetLedger/Pending/${orgNumber}/${currentDate}`;
  const response = await client<RawTxResponse>(url);

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const rejectPendingLedgerRollback = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  initialisedDate: string,
  date: string
) => {
  const client = useClient({ hasAuth: true });
  const response = await client<RawTxResponse>(
    `${URL.ADMIN}/Transaction/RollbackLedger/Rejected/${orgNumber}/${initialisedDate}/${date}`
  );

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const rollbackLedger = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date?: string
) => {
  const client = useClient({ hasAuth: true });
  const url = date
    ? `${URL.ADMIN}/Transaction/RollbackLedger/${orgNumber}/${date}`
    : `${URL.ADMIN}/Transaction/ResetLedger/${orgNumber}`;
  const response = await client<RawTxResponse>(url);

  const result = RawTxResponseSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(result.error, {
      contexts: { response, result },
    });

    return blockchainClient.sendTransaction(response.rawTx);
  }

  return blockchainClient.sendTransaction(result.data.rawTx);
};

const useStartApprovalMutation = (
  orgNumber: string,
  date?: string,
  approve?: boolean,
  options: UseMutationOptions<unknown, IRequestError | TxRejectedError> = {}
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, IRequestError>(
    () => {
      if (!date) {
        throw new Error("No date for approval");
      }
      return createPendingLedgerApproval(
        blockchainClient,
        orgNumber,
        date,
        approve || false
      );
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["approvalInfo", orgNumber],
        });
        queryClient.invalidateQueries({
          queryKey: ["companyInvolvements"],
        });
        queryClient.invalidateQueries({
          queryKey: ["parentEvents", orgNumber],
        });

        if (options.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useApproveLedgerMutation = (
  orgNumber: string,
  date?: string,
  options: UseMutationOptions<unknown, IRequestError | TxRejectedError> = {}
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, IRequestError>(
    () => {
      if (date === undefined) {
        const err = TypeError("Date is undefined");
        monitoring.captureException(err, {
          contexts: { args: { orgNumber, date } },
        });
        throw err;
      }

      return approveLedger(blockchainClient, orgNumber, date);
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["approvalInfo", orgNumber],
        });
        queryClient.invalidateQueries({
          queryKey: ["companyInvolvements"],
        });
        queryClient.invalidateQueries({
          queryKey: ["parentEvents", orgNumber],
        });

        if (options.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useRejectLedgerMutation = (
  orgNumber: string,
  currentVersion: LedgerVersion,
  rollbackPending?: LedgerRollbackPending,
  initialisedEvent?: LedgerApprovalInitializedEvent,
  options: UseMutationOptions<unknown, IRequestError | TxRejectedError> = {}
) => {
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, IRequestError>(
    () => {
      if (rollbackPending) {
        return rejectPendingLedgerRollback(
          blockchainClient,
          orgNumber,
          rollbackPending.date,
          currentVersion
        );
      }
      if (initialisedEvent) {
        return rejectLedgerApproval(
          blockchainClient,
          orgNumber,
          initialisedEvent.date,
          currentVersion
        );
      }
      throw Error("No pending event to reject");
    },
    {
      ...options,
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries({
          queryKey: ["parentEvents", orgNumber],
        });

        if (options.onSuccess) {
          options.onSuccess(data, vars, context);
        }
      },
    }
  );
};

const useRestoreLedgerMutation = (
  orgNumber: string,
  currentDate: string,
  date?: string,
  isDraft?: boolean,
  options?: UseMutationOptions<unknown, IRequestError | TxRejectedError>
): UseMutationResult<unknown, IRequestError | TxRejectedError, void> => {
  // Rollback approved events
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<unknown, IRequestError>(
    () => {
      if (isDraft) {
        return rollbackLedger(blockchainClient, orgNumber, date);
      }
      return createPendingLedgerRollback(
        blockchainClient,
        orgNumber,
        currentDate,
        date
      );
    },
    {
      onSettled: (...args) => {
        if (typeof options?.onSettled === "function") {
          options.onSettled(...args);
        }

        // When the ledger is restored, the company is no longer onboarded, so
        // we need to invalidate the companyInvolvements query to refetch the
        // data, to make companyInvolvements reflect the new state.
        queryClient.invalidateQueries(["companyInvolvements"]);
      },
      ...options,
    }
  );
};

const useRollbackDraftEventMutation = (
  orgNumber: string,
  onSuccess?: () => void
): UseMutationResult<
  unknown,
  IRequestError | TxRejectedError,
  { date: string }
> => {
  // Rollback draft events
  const blockchainClient = useBlockchainClient();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    IRequestError | TxRejectedError,
    { date: string }
  >(({ date }) => rollbackLedger(blockchainClient, orgNumber, date), {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      queryClient.invalidateQueries(["parentEvents", orgNumber]);
      queryClient.invalidateQueries(["companyShareholders", orgNumber, ""]);
    },
  });
};

const shareBlockHistory = async (orgNumber: string, ledgerVersion: string) => {
  const client = useClient({ hasAuth: true });
  return await client<ShareBlockHistory[]>(
    `${URL.SEARCH}/ShareRangeHistory/${orgNumber}/${ledgerVersion}`
  );
};

const useShareBlockHistory = (
  orgNumber: string,
  ledgerVersion: string | undefined
) =>
  useQuery(
    ["shareBlockHistory", orgNumber, ledgerVersion],
    () => shareBlockHistory(orgNumber, ledgerVersion!),
    { enabled: ledgerVersion !== undefined }
  );

const ledgerDocumentDownload = async (
  orgNumber: string,
  ledgerVersion?: LedgerVersion
) => {
  const authHeader = getAuthHeader();
  return fetch(
    `${URL.ADMIN}/Document/LedgerDocument/${orgNumber}/${ledgerVersion}`,
    { method: "POST", headers: { Authorization: JSON.stringify(authHeader) } }
  );
};

const shareholdersDocumentDownload = async (
  orgNumber: string,
  ledgerVersion?: LedgerVersion,
  selectedPrograms?: string[]
) => {
  const authHeader = getAuthHeader();
  return fetch(
    `${URL.ADMIN}/Document/Shareholders/${orgNumber}/${ledgerVersion}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.stringify(authHeader),
      },
      body: JSON.stringify({ selectedPrograms }),
    }
  );
};

const votingRegisterDocumentDownload = async (
  orgNumber: string,
  shareholders: { [id: string]: string | undefined }
) => {
  const authHeader = getAuthHeader();
  return fetch(`${URL.ADMIN}/Document/Voting/${orgNumber}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: JSON.stringify(authHeader),
    },
    body: JSON.stringify({ shareholders }),
  });
};

export {
  ledgerDocumentDownload,
  rejectLedgerApproval,
  rejectPendingLedgerRollback,
  rollbackLedger,
  shareholdersDocumentDownload,
  unmaskValues,
  useApproveLedgerMutation,
  useRejectLedgerMutation,
  useRestoreLedgerMutation,
  useRollbackDraftEventMutation,
  useShareBlockHistory,
  useStartApprovalMutation,
  votingRegisterDocumentDownload,
};
