import { Route, Routes as ReactRouterRoutes } from "react-router-dom";

import { TruidCompleteLoginPage } from "../auth/Truid/TruidCompleteLogin";
import { TruidCompleteSignPage } from "../auth/Truid/TruidCompleteSignPage";
import { useToggles } from "../hooks/useToggles";
import { HomePage } from "../pages";
import { AdministrationPage } from "../pages/Administration";
import { CompanyAdministrationPage } from "../pages/Administration/[companyId]";
import { FeatureTogglesPage } from "../pages/Administration/FeatureToggles";
import { CompanyPage, CompanyRedirect } from "../pages/companies/[companyId]";
import { CompanyInformationPage } from "../pages/companies/[companyId]/company-information";
import { CompanyEventsPage } from "../pages/companies/[companyId]/events";
import { CompanyEventsValidationPage } from "../pages/companies/[companyId]/events/validate";
import { CompanySettingsPage } from "../pages/companies/[companyId]/settings";
import { CompanyShares } from "../pages/companies/[companyId]/shares";
import { OnboardingPage } from "../pages/CompanySettings/Onboarding/Onboarding";
import VotingRegister from "../pages/CompanyShares/VotingRegister";
import { EmailVerificationPage } from "../pages/emailVerification";
import { ExternalViewPage } from "../pages/ExternalView";
import { OptionsPage } from "../pages/Options/Options";
import OptionsDetailPage from "../pages/Options/OptionsDetailPage/OptionsDetailPage";
import {
  CompanyOwnershipDetailed,
  PersonalOwnershipDetailed,
  RepresentativeOwnershipDetailed,
} from "../pages/ownership/Detailed";
import {
  CompanyOwnershipList,
  PersonalOwnershipList,
  RepresentativeOwnershipList,
} from "../pages/ownership/List";
import { SignInPage } from "../pages/sign-in";
import { ViewSharingPage } from "../pages/ViewSharing";
import * as monitoring from "../utils/monitoring";
import { APP_ROUTE } from "./constants";
import Private from "./Private";

const SentryRoutes =
  monitoring.withSentryReactRouterV6Routing(ReactRouterRoutes);

const Routes = () => {
  // Listens for toggles changes in the URL
  useToggles();

  return (
    <SentryRoutes>
      <Route
        element={<TruidCompleteLoginPage />}
        path={APP_ROUTE.TRU_ID_COMPLETE_SIGN_IN}
      />
      <Route
        element={<TruidCompleteSignPage />}
        path={APP_ROUTE.TRU_ID_COMPLETE_SIGN}
      />
      <Route
        element={<EmailVerificationPage />}
        path="companies/:companyId/email/verify"
      />
      <Route
        element={<ExternalViewPage />}
        path="external/:companyId/views/:viewId"
      />
      <Route element={<SignInPage />} path={APP_ROUTE.SIGN_IN} />
      <Route element={<Private />}>
        <Route element={<CompanyPage />} path={APP_ROUTE.COMPANIES}>
          <Route element={<CompanyEventsPage />} path=":companyId/events" />
          <Route
            element={<CompanyEventsValidationPage />}
            path=":companyId/bv-validation"
          />
          <Route
            element={<CompanyInformationPage />}
            path=":companyId/company-information"
          />
          <Route element={<CompanyShares />} path=":companyId/shares/*" />
          <Route element={<OnboardingPage />} path=":companyId/onboarding" />
          <Route element={<OptionsPage />} path=":companyId/options" />
          <Route
            element={<OptionsDetailPage />}
            path=":companyId/options/:optionsId"
          />
          <Route element={<ViewSharingPage />} path=":companyId/views" />
          <Route
            path=":companyId/voting-register"
            element={<VotingRegister />}
          />
          <Route
            element={<CompanySettingsPage />}
            path=":companyId/settings/*"
          />
          <Route element={<CompanyRedirect />} path=":companyId/*" />
        </Route>
        <Route path={APP_ROUTE.OWNERSHIP}>
          <Route element={<PersonalOwnershipList />} path="private" />
          <Route element={<CompanyOwnershipList />} path="company/:companyId" />
          <Route
            element={<RepresentativeOwnershipList />}
            path="representative/:holderId"
          />
          <Route
            element={<PersonalOwnershipDetailed />}
            path="private/:companyId"
          />
          <Route
            element={<RepresentativeOwnershipDetailed />}
            path="representative/:entityId/:companyId"
          />
          <Route
            element={<CompanyOwnershipDetailed />}
            path="company/:holdingCompanyNr/:companyId"
          />
        </Route>
        <Route path={APP_ROUTE.ADMINISTRATION}>
          <Route element={<CompanyAdministrationPage />} path=":companyId/*" />
          <Route index element={<AdministrationPage />} />
          <Route element={<FeatureTogglesPage />} path="features" />
        </Route>
        <Route element={<HomePage />} path={APP_ROUTE.HOME} />
      </Route>
    </SentryRoutes>
  );
};

export default Routes;
