import type React from "react";

import { clsxm } from "../../../utils/tailwind";
import { ChevronDownIcon, ChevronUpIcon } from "../icons";

type ColumnProps = {
  title: string;
  sortAsc: boolean;
  sortDesc: boolean;
  onClick: () => void;
  className?: string;
  sortable?: boolean;
  clickable?: boolean;
};

const Column: React.FC<ColumnProps> = ({
  title,
  sortAsc,
  sortDesc,
  onClick,
  className,
  clickable,
  sortable = true,
}) => {
  if (!clickable) {
    return (
      <div
        className={clsxm("tw-items-center tw-gap-1 tw-text-left", className)}
      >
        <p className="tw-text-sm tw-text-neutral-500">{title}</p>
      </div>
    );
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={clsxm(
        "tw-flex tw-items-center tw-gap-1 tw-text-left",
        className
      )}
    >
      <p className="tw-text-sm tw-text-neutral-500">{title}</p>
      {sortable && (
        <div className="tw-flex tw-flex-col tw-gap-0">
          <ChevronUpIcon
            className={`${
              sortDesc ? "tw-text-neutral-800" : "tw-text-neutral-300"
            }`}
            weight="bold"
            size={12}
          />
          <ChevronDownIcon
            className={`${
              sortAsc ? "tw-text-neutral-800" : "tw-text-neutral-300"
            }`}
            weight="bold"
            size={12}
          />
        </div>
      )}
    </button>
  );
};

export { Column };
