const calculateRatio = (before: number, after: number) => {
  let a = 1;
  let b = after / before;

  // Multiply to make ratio whole numbers
  const decimalPart = b.toString().split(".")[1];
  if (decimalPart) {
    const decimalLength = decimalPart.length;
    a *= 10 ** decimalLength;
    b *= 10 ** decimalLength;
  }

  // Function to find GCD
  const gcd = (x: number, y: number): number => {
    return y === 0 ? x : gcd(y, x % y);
  };

  const divisor = gcd(a, b);
  return { x: a / divisor, y: b / divisor };
};

export { calculateRatio };
